<template>
  <div>
    <draggable
      v-model="myList"
      :disabled="!allows_crud"
      handle=".handle-descriptor"
    >
      <transition-group type="transition">
        <div
          :class="{
            'program-alter-container mb-2': ProgramView,
          }"
          v-for="element in myList"
          :key="element.id"
        >
          <template
            v-if="
              Title.find((x) => x.id == element.descriptor) &&
              Title.find((x) => x.id == element.descriptor).internal_use_id ==
                'duoc_pmd_methodological_strategy' &&
              matter
            "
          >
            <MethodologicalStrategyTable
              :ProfileMatter="matter"
              :allows_crud="false"
            ></MethodologicalStrategyTable>
          </template>
          <template
            v-if="
              Title.find((x) => x.id == element.descriptor) &&
              (Title.find((x) => x.id == element.descriptor).internal_use_id ==
                'duoc_pmd_te_evaluation_situation_expanded' ||
                Title.find((x) => x.id == element.descriptor).internal_use_id ==
                  'duoc_pmd_te_orientations_expanded' ||
                Title.find((x) => x.id == element.descriptor).internal_use_id ==
                  'duoc_pmd_matter_description' ||
                Title.find((x) => x.id == element.descriptor).internal_use_id ==
                  'duoc_key_suggestions_and_warnings_expanded') &&
              matter
            "
          >
            <DuocSpecialDescriptor
              v-if="institution && institution.internal_use_id == 'duoc_uc'"
              :internal_use_id="
                Title.find((x) => x.id == element.descriptor).internal_use_id
              "
              :matter="matter"
              :allows_crud="allows_crud"
              :PagePosition="PagePosition"
              :Text_info_tooltip="Text_info_tooltip"
              :Title_text="Title_text"
              :show_deleted_button="true"
              @updateState="(value) => $emit('updateState', value)"
              @createTitle="(value) => $emit('createTitle', value)"
              @updateTitle="(value) => $emit('updateTitle', value)"
              @createDescription="
                (object, text) => $emit('createDescription', object, text)
              "
              @deleteDescriptorsContent="deleteDescriptors"
              @updateDescription="
                (object, text) => $emit('updateDescription', object, text)
              "
            ></DuocSpecialDescriptor>
          </template>
          <div
            v-if="
              Title.find(
                (x) =>
                  x.id == element.descriptor && x.page_position == PagePosition
              ) &&
              (element.is_visible ||
                (allows_crud &&
                  isClosedOldMatterPartial(
                    Title.find((x) => x.id == element.descriptor)
                      .internal_use_id
                  ))) &&
              (element.description.trim() != 0 ||
                (allows_crud &&
                  isClosedOldMatterPartial(
                    Title.find((x) => x.id == element.descriptor)
                      .internal_use_id
                  )))
            "
            class="mb-2 outlined-div-secondary"
            :class="{ 'outlined-tlr-div-black': ProgramView }"
          >
            <div
              v-if="
                element.description.trim() != 0 ||
                (allows_crud &&
                  isClosedOldMatterPartial(
                    Title.find((x) => x.id == element.descriptor)
                      .internal_use_id
                  ))
              "
              class="descriptor-title"
              :class="{
                'text-light border-bottom-black btn-center': ProgramView,
              }"
            >
              <div
                v-if="
                  allows_crud &&
                  isClosedOldMatterPartial(
                    Title.find((x) => x.id == element.descriptor)
                      .internal_use_id
                  )
                "
                class="question-move handle-descriptor"
                :class="{
                  'grabbable-cursor':
                    allows_crud &&
                    isClosedOldMatterPartial(
                      Title.find((x) => x.id == element.descriptor)
                        .internal_use_id
                    ),
                }"
              >
                <b-icon
                  class="move-icon"
                  icon="arrows-expand"
                  scale="1.3"
                ></b-icon>
              </div>
              <span class="descriptor-order" v-if="VisibleOrder">
                {{ element.order }}
              </span>
              <strong class="w-100" :class="{ 'custom-title': !ProgramView }">{{
                Title.find((x) => x.id == element.descriptor).title
              }}</strong>
              <template
                v-if="
                  allows_crud &&
                  isClosedOldMatterPartial(
                    Title.find((x) => x.id == element.descriptor)
                      .internal_use_id
                  )
                "
              >
                <span>
                  <b-button
                    v-if="show_visible"
                    size="sm"
                    class="ml-1 p-0"
                    variant="none"
                    @click="showDescriptor(element)"
                  >
                    <template v-if="element.is_visible">
                      <b-icon
                        class="btn-actions"
                        icon="eye"
                        v-b-tooltip.v-secondary.noninteractive="`Mostrar`"
                        scale="1"
                      ></b-icon>
                    </template>
                    <template v-else>
                      <b-icon
                        class="btn-actions"
                        v-b-tooltip.v-secondary.noninteractive="`Ocultar`"
                        icon="eye-slash"
                        scale="1"
                      ></b-icon>
                    </template>
                  </b-button>
                </span>
                <ButtonSmall
                  :tooltip_text="`Editar`"
                  :click_button="
                    () =>
                      slotUpdatedTitle(
                        Title.find((x) => x.id == element.descriptor)
                      )
                  "
                  :class="`ml-1`"
                  :icon="'square'"
                ></ButtonSmall>
                <!-- <button-edit
                  class="ml-1 p-0"
                  @click="
                    slotUpdatedTitle(
                      Title.find((x) => x.id == element.descriptor)
                    )
                  "
                  v-b-tooltip.v-secondary.noninteractive="`Editarss`"
                >
                </button-edit> -->
                <!-- <span
                  v-if="
                    institution &&
                    institution.internal_use_id == 'duoc_uc' &&
                    Title.find((x) => x.id == element.descriptor) &&
                    Title.find((x) => x.id == element.descriptor)
                      .internal_use_id != null &&
                    ProgramView
                  "
                  v-b-tooltip.v-secondary.auto.noninteractive="
                    `Este objeto es necesarios para el funcionamiento de la maleta didáctica.`
                  "
                >
                  <button-delete disabled></button-delete>
                </span> -->
                <!-- Eliminar -->
                <ButtonSmall
                  :click_button="() => deleteDescriptors(element)"
                  :tooltip_text="`Limpiar Contenido`"
                  :class="`mr-1`"
                  :icon="'trash'"
                ></ButtonSmall>
                <!-- <b-button
                  class="ml-1 mr-1 p-0"
                  variant="none"
                  @click="deleteDescriptors(element)"
                  v-b-tooltip.v-secondary.noninteractive="`Limpiar Contenido`"
                >
                  <b-icon-x-square></b-icon-x-square>
                </b-button> -->
              </template>
            </div>
            <div>
              <div
                class="descriptor-body pt-2 pb-3 rich-text-content"
                v-html="element.description"
              ></div>
              <div
                v-if="
                  allows_crud &&
                  isClosedOldMatterPartial(
                    Title.find((x) => x.id == element.descriptor)
                      .internal_use_id
                  )
                "
              >
                <ButtonSmall
                  :tooltip_text="`${
                    Title.find((x) => x.id == element.descriptor).title
                  }`"
                  :click_button="
                    () =>
                      $bvModal.show(
                        `${ref_component}edit-description-modal-${element.id}`
                      )
                  "
                  :class="`btn-center`"
                  :icon="'square'"
                ></ButtonSmall>
                <!-- <button-edit
                  class="btn-center"
                  @click="
                    $bvModal.show(
                      `${ref_component}edit-description-modal-${element.id}`
                    )
                  "
                  v-b-tooltip.v-secondary.noninteractive="
                    `${Title.find((x) => x.id == element.descriptor).title}`
                  "
                >
                </button-edit> -->
                <b-modal
                  :id="`${ref_component}edit-description-modal-${element.id}`"
                  :title="`${
                    Title.find((x) => x.id == element.descriptor).title
                  }`"
                  size="xl"
                  hide-footer
                  ignore-enforce-focus-selector="#sidebar-right"
                >
                  <template v-if="rich_text_editor">
                    <NewRichTextEditor
                      :Object="element"
                      :Text="element.description"
                      :permit_blank="true"
                      :show_last_modification="true"
                      :object_last_modification="element"
                      @save="updateDescription"
                      @close="
                        $bvModal.hide(
                          `${ref_component}edit-description-modal-${element.id}`
                        )
                      "
                    ></NewRichTextEditor>
                  </template>
                  <template v-else>
                    <div>
                      <TextArea
                        :showSaveBtn="true"
                        :clearTextArea="false"
                        :Text="element.description"
                        :show_last_modification="true"
                        :object_last_modification="element"
                        @update="
                          (Text) => {
                            updateDescription(element, Text);
                            $bvModal.hide(
                              `${ref_component}edit-description-modal-${element.id}`
                            );
                          }
                        "
                      ></TextArea>
                    </div>
                  </template>
                </b-modal>
              </div>
            </div>
          </div>
          <!-- FormationLine Description -->
          <template
            v-if="
              getFormationLineDescription != null &&
              Title.find((x) => x.id == element.descriptor) &&
              Title.find((x) => x.id == element.descriptor).internal_use_id ==
                'duoc_pmd_matter_description' &&
              ProgramView &&
              matter
            "
          >
            <div
              class="my-2 outlined-div-secondary"
              :class="{ 'outlined-div-black': ProgramView }"
            >
              <div
                class="descriptor-title"
                :class="{
                  'border-bottom-black':
                    ProgramView &&
                    allows_crud &&
                    isClosedOldMatterPartial(
                      Title.find((x) => x.id == element.descriptor)
                        .internal_use_id
                    ),
                  'text-light btn-center': ProgramView,
                }"
              >
                <strong>
                  Descripción de la
                  {{
                    $getVisibleNames(
                      "mesh.formationline",
                      false,
                      "Línea De Formación"
                    )
                  }}</strong
                >
              </div>
              <div
                class="descriptor-body pt-2 pb-3 rich-text-content"
                v-html="getFormationLineDescription"
              ></div>
            </div>
          </template>
        </div>
      </transition-group>
    </draggable>

    <div v-for="item in Title" :key="item.id">
      <template
        v-if="
          myList.filter((x) => x.descriptor == item.id).length == 0 &&
          allows_crud &&
          item.internal_use_id == 'duoc_pmd_methodological_strategy' &&
          matter
        "
      >
        <MethodologicalStrategyTable
          :ProfileMatter="matter"
          :allows_crud="false"
        ></MethodologicalStrategyTable>
      </template>
      <template
        v-if="
          myList.filter((x) => x.descriptor == item.id).length == 0 &&
          allows_crud &&
          (item.internal_use_id ==
            'duoc_pmd_te_evaluation_situation_expanded' ||
            item.internal_use_id == 'duoc_pmd_matter_description' ||
            item.internal_use_id == 'duoc_pmd_te_orientations_expanded' ||
            item.internal_use_id ==
              'duoc_key_suggestions_and_warnings_expanded') &&
          matter
        "
      >
        <DuocSpecialDescriptor
          v-if="institution && institution.internal_use_id == 'duoc_uc'"
          :internal_use_id="item.internal_use_id"
          :matter="matter"
          :allows_crud="allows_crud"
          :PagePosition="PagePosition"
          :Text_info_tooltip="Text_info_tooltip"
          :Title_text="Title_text"
          @updateState="(value) => $emit('updateState', value)"
          @createTitle="(value) => $emit('createTitle', value)"
          @updateTitle="(value) => $emit('updateTitle', value)"
          @createDescription="
            (object, text) => $emit('createDescription', object, text)
          "
          @updateDescription="
            (object, text) => $emit('updateDescription', object, text)
          "
        ></DuocSpecialDescriptor>
      </template>
      <div
        class="my-2 outlined-div-secondary"
        :class="{ 'outlined-div-black': ProgramView }"
        v-if="
          myList.filter((x) => x.descriptor == item.id).length == 0 &&
          (allows_crud || show_matter_descriptor) &&
          isClosedOldMatterPartial(item.internal_use_id)
        "
      >
        <div
          class="descriptor-title"
          :class="{
            'border-bottom-black':
              ProgramView &&
              allows_crud &&
              isClosedOldMatterPartial(item.internal_use_id),
            'text-light btn-center': ProgramView,
          }"
        >
          <strong class="w-100">{{ item.title }}</strong>
          <template
            v-if="allows_crud && isClosedOldMatterPartial(item.internal_use_id)"
          >
            <ButtonSmall
              :tooltip_text="`Editar`"
              :click_button="() => slotUpdatedTitle(item)"
              :class="`ml-1`"
              :icon="'square'"
            ></ButtonSmall>
            <!-- <button-edit
              class="m-0 ml-1"
              @click="slotUpdatedTitle(item)"
              v-b-tooltip.v-secondary.noninteractive="`Editar`"
            >
            </button-edit> -->
            <span
              v-if="
                institution &&
                institution.internal_use_id == 'duoc_uc' &&
                item.internal_use_id != null &&
                ProgramView
              "
              v-b-tooltip.v-secondary.auto.noninteractive="
                `Este objeto es necesario para el funcionamiento de la maleta didáctica.`
              "
            >
              <ButtonSmall
                :tooltip_text="``"
                :class="`mr-1`"
                :disabled_button="true"
                :icon="'trash'"
              ></ButtonSmall>
              <!-- <button-delete disabled></button-delete> -->
            </span>
            <!-- Eliminar -->
            <ButtonSmall
              v-else-if="user && (user.is_superuser || user.groups.includes(1))"
              :click_button="() => deleteTitle(item)"
              :tooltip_text="`Eliminar`"
              :class="`ml-1`"
              :icon="'trash'"
            ></ButtonSmall>
            <!-- <button-delete
              v-else-if="user && (user.is_superuser || user.groups.includes(1))"
              class="ml-1 p-0"
              @click="deleteTitle(item)"
              v-b-tooltip.v-secondary.noninteractive="`Eliminar`"
            ></button-delete> -->
          </template>
        </div>
        <!-- class="descriptor-body" -->
        <div
          v-if="allows_crud && isClosedOldMatterPartial(item.internal_use_id)"
        >
          <ButtonSmall
            :tooltip_text="`${item.title}`"
            :click_button="
              () =>
                $bvModal.show(
                  `${ref_component}add-description-modal-${item.id}`
                )
            "
            :class="`btn-center`"
            :icon="'plus'"
            :variant="'none'"
          >
          </ButtonSmall>
          <!-- <button-add
            class="btn-center"
            @click="
              $bvModal.show(`${ref_component}add-description-modal-${item.id}`)
            "
            v-b-tooltip.v-secondary.noninteractive="`${item.title}`"
          >
          </button-add> -->
          <b-modal
            :id="`${ref_component}add-description-modal-${item.id}`"
            :title="`${item.title}`"
            size="xl"
            hide-footer
            ignore-enforce-focus-selector="#sidebar-right"
          >
            <template v-if="rich_text_editor">
              <NewRichTextEditor
                :Type="item"
                :permit_blank="true"
                @save="createDescription"
                @close="
                  $bvModal.hide(
                    `${ref_component}add-description-modal-${item.id}`
                  )
                "
              ></NewRichTextEditor>
            </template>
            <template v-else>
              <div>
                <TextArea
                  :showSaveBtn="true"
                  :clearTextArea="false"
                  @update="
                    (Text) => {
                      createDescription(Text, item);
                      $bvModal.hide(
                        `${ref_component}add-description-modal-${item.id}`
                      );
                    }
                  "
                ></TextArea>
              </div>
            </template>
          </b-modal>
        </div>
      </div>
      <!-- FormationLine Description -->
      <template
        v-if="
          myList.filter((x) => x.descriptor == item.id).length == 0 &&
          allows_crud &&
          getFormationLineDescription != null &&
          item.internal_use_id == 'duoc_pmd_matter_description' &&
          ProgramView &&
          matter
        "
      >
        <div
          class="my-2 outlined-div-secondary"
          :class="{ 'outlined-div-black': ProgramView }"
        >
          <div
            class="descriptor-title"
            :class="{
              'border-bottom-black':
                ProgramView &&
                allows_crud &&
                isClosedOldMatterPartial(item.internal_use_id),
              'text-light btn-center': ProgramView,
            }"
          >
            <strong>
              Descripción de la
              {{
                $getVisibleNames(
                  "mesh.formationline",
                  false,
                  "Línea De Formación"
                )
              }}</strong
            >
          </div>
          <div
            class="descriptor-body pt-2 pb-3 rich-text-content"
            v-html="getFormationLineDescription"
          ></div>
        </div>
      </template>
    </div>
    <b-modal
      :id="`${ref_component}title-modal`"
      :title="isNaN(title_proccess.id) ? `Crear título` : `Editar título`"
      size="xl"
      @hide="resetTitleModal"
      hide-footer
      ignore-enforce-focus-selector="#sidebar-right"
    >
      <b-form-group
        label-cols="0"
        label-cols-sm="2"
        label-for="descriptor-title-input"
      >
        <template #label>
          <span id="descriptor-title-input" class="descriptor-title-label"
            >Título</span
          >
        </template>
        <div>
          <div>
            <b-form-input
              id="descriptor-title-input"
              v-model="title_proccess.title"
              aria-describedby="input-title-live-feedback"
              size="sm"
            ></b-form-input>
            <b-form-invalid-feedback id="input-title-live-feedback">
              <div>Este campo debe tener al menos 1 caracteres.</div>
            </b-form-invalid-feedback>
          </div>
          <div class="mt-2 d-flex w-100">
            <template v-if="show_is_default">
              <template v-if="title_proccess.internal_use_id == null">
                <b-form-checkbox
                  class="descriptor-title-check w-100"
                  v-model="title_proccess.is_default"
                  >Predeterminado
                  <InfoTooltip
                    class="selection-info ml-0"
                    :tooltip_text="`${Text_info_tooltip}`"
                  >
                  </InfoTooltip>
                </b-form-checkbox>
              </template>
              <template v-else>
                <b-form-checkbox
                  class="descriptor-title-check w-100"
                  :checked="title_proccess.is_default"
                  :disabled="true"
                  >Predeterminado
                  <InfoTooltip
                    class="selection-info ml-0"
                    :tooltip_text="`${Text_info_tooltip}`"
                  >
                  </InfoTooltip>
                </b-form-checkbox>
              </template>
            </template>
          </div>
        </div>
      </b-form-group>
      <b-button
        size="sm"
        variant="primary"
        class="float-right"
        @click="save(title_proccess)"
      >
        <!-- :disabled="title_proccess.title.trim().length < 0" -->
        Guardar Título
      </b-button>
    </b-modal>
    <div>
      <ButtonMedium
        v-if="allows_crud && show_add_button"
        :tooltip_text="``"
        :custom_class="`my-2`"
        :click_button="() => $bvModal.show(`${ref_component}title-modal`)"
        :text_button="`Agregar Descriptor`"
        :icon="'plus'"
        :variant="'primary'"
      >
      </ButtonMedium>
      <!-- <b-button
        v-if="allows_crud && show_add_button"
        size="sm"
        class="secondary-button my-2"
        @click="$bvModal.show(`${ref_component}title-modal`)"
        ><b-icon-plus></b-icon-plus>Agregar Descriptor</b-button
      > -->
      <b-button
        v-if="PagePosition == 2 && ref_component == 'EgressProfileDescriptor2'"
        variant="secondary"
        size="sm"
        class="secondary-button my-2 ml-3 noprint"
        @click="$bvModal.show('egressprofile-file-modal')"
      >
        Anexos
      </b-button>
    </div>
  </div>
</template>

<script>
import * as names from "@/store/names";
import { mapGetters } from "vuex";
import draggable from "vuedraggable";
import { generateUniqueId } from "@/utils/utils";

export default {
  name: "DescriptorsContainer",
  components: {
    draggable,
    TextArea: () => import("@/components/reusable/TextArea"),
    NewRichTextEditor: () => import("@/components/reusable/NewRichTextEditor"),
    InfoTooltip: () => import("@/components/reusable/InfoTooltip"),
    MethodologicalStrategyTable: () =>
      import(
        "@/components/teaching/MatterProgram/Components/MethodologicalStrategyTable"
      ),
    DuocSpecialDescriptor: () =>
      import("@/components/mesh/Matrix2/DuocSpecialDescriptor"),
    ButtonSmall: () =>
      import("@/components/reusable/Buttons/Small/ButtonSmall"),
    ButtonMedium: () =>
      import("@/components/reusable/Buttons/Medium/ButtonMedium"),
  },
  props: {
    ref_component: {
      type: String,
      default: "",
    },
    Title: {
      type: Array,
    },
    Descriptors: {
      type: Array,
    },
    Title_text: {
      type: String,
      default: "",
    },
    Text_info_tooltip: {
      type: String,
      default: "",
    },
    allows_crud: {
      type: Boolean,
      default: false,
    },
    show_add_button: {
      type: Boolean,
      default: true,
    },
    Order: {
      type: Number,
      default: 1,
    },
    PagePosition: {
      type: Number,
      default: 1,
    },
    VisibleOrder: {
      type: Boolean,
      default: false,
    },
    ProgramView: {
      type: Boolean,
      default: false,
    },
    show_is_default: {
      type: Boolean,
      default: true,
    },
    show_visible: {
      type: Boolean,
      default: true,
    },
    rich_text_editor: {
      type: Boolean,
      default: true,
    },
    show_matter_descriptor: {
      type: Boolean,
      default: false,
    },
    // Necesario para renderizar el componente MethodologicalStrategyTable que va a ser mostrado arriba del descriptor con internal_use_id = duoc_pmd_methodological_strategy
    matter: {
      type: Object,
      default: null,
    },
    // Necesario para restringir los descriptores duoc_pmd_te_evaluation_situation, duoc_pmd_te_orientations,duoc_pmd_matter_description_superior, duoc_pmd_outside_activities_indications cuando se cierre el candado.
    oldMatter: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      title_proccess: {
        id: generateUniqueId(),
        title: "",
        page_position: this.PagePosition,
        is_default: true,
        internal_use_id: null,
      },
    };
  },
  computed: {
    ...mapGetters({
      user: "getUser",
      institution: "getInstitution",
      formation_lines: names.FORMATION_LINES,
    }),
    myList: {
      get() {
        let list = this.Descriptors;
        list.sort(function (a, b) {
          if (a.order < b.order) return -1;
          if (a.order > b.order) return 1;
          return 0;
        });
        return list;
      },
      set(list) {
        let order = this.Order;
        list.forEach((element) => {
          if (element.order != order) element.order = order;
          order += 1;
        });
        this.$emit("updateOrder", list);
      },
    },
    getFormationLineDescription() {
      if (!this.matter) return null;
      const matter_formation_line = this.formation_lines.find(
        (x) => this.matter.formation_line == x.id
      );
      return matter_formation_line ? matter_formation_line.description : null;
    },
  },
  methods: {
    isClosedOldMatterPartial(internal_use_id) {
      if (this.oldMatter) {
        if (
          (this.institution && this.institution.internal_use_id == "duoc_uc") ||
          this.$debug_change_duoc
        ) {
          if (
            this.$equals(internal_use_id, "duoc_pmd_te_evaluation_situation") ||
            this.$equals(internal_use_id, "duoc_pmd_te_orientations") ||
            this.$equals(
              internal_use_id,
              "duoc_pmd_matter_description_superior"
            ) ||
            this.$equals(internal_use_id, "duoc_key_suggestions_and_warnings")
          ) {
            return !this.oldMatter.descriptors_locked_view;
          } else return true;
        } else {
          return !this.oldMatter.descriptors_locked_view;
        }
      } else return true;
    },
    showDescriptor(item) {
      this.$emit("updateState", item);
    },
    slotUpdatedTitle(item) {
      this.title_proccess.id = item.id;
      this.title_proccess.title = item.title;
      this.title_proccess.is_default = item.is_default;
      if (item.internal_use_id != null)
        this.title_proccess.internal_use_id = item.internal_use_id;
      this.$bvModal.show(`${this.ref_component}title-modal`);
    },
    resetTitleModal() {
      this.title_proccess.id = generateUniqueId();
      this.title_proccess.title = "";
      this.title_proccess.is_default = false;
      this.title_proccess.internal_use_id = null;
    },
    updateDescription(object, text) {
      this.$emit("updateDescription", object, text);
    },
    save(title) {
      title.page_position = this.PagePosition;
      if (isNaN(title.id)) {
        title.internal_use_id = null;
        this.$emit("createTitle", title);
        this.$bvModal.hide(`${this.ref_component}title-modal`);
      } else {
        this.$swal({
          title: "¿Está seguro de que desea editar el Descriptor?",
          text: `"Esto podria afectar a otros ${this.Title_text}!"`,
          type: "warning",
          showCancelButton: true,
        }).then((result) => {
          if (result.value) {
            this.$emit("updateTitle", title);
            this.$bvModal.hide(`${this.ref_component}title-modal`);
          }
        });
      }
    },
    deleteDescriptors(item) {
      if (item) {
        if (!isNaN(item.id) && !isNaN(item.descriptor)) {
          this.$emit("deleteDescriptors", item);
        }
      }
    },
    deleteTitle(item) {
      // Mostrar todos los objetos que se van a eliminar.
      if (!isNaN(item.id)) {
        this.$emit("deleteTitle", item);
      }
    },
    createDescription(text, object) {
      this.$emit("createDescription", text, object);
    },
  },
  created() {},
};
</script>
<style scoped src="@/utils/rich_text_editor.css">
</style>
<style scoped>
.grabbing * {
  cursor: grabbing !important;
}
.question-move {
  background: var(--primary-color);
  width: 25px;
  position: absolute;
  left: 20px;
}
.question-move:hover {
  color: white;
}
.move-icon {
  cursor: grab;
  cursor: -moz-grab;
  cursor: -webkit-grab;
}
.move-icon:active {
  cursor: grabbing;
  cursor: -moz-grabbing;
  cursor: -webkit-grabbing;
}
.program-alter-container {
  padding-inline: 0;
}
.descriptor-order {
  float: left;
  padding-block: 0.3em;
  padding-inline: 7vh;
  border-right: 1px solid var(--secondary-color);
  transform: translateY(-15%);
}
.descriptor-title {
  background-color: var(--primary-color);
  padding-block: 0.3em;
  color: black !important;
}
.custom-title {
  margin-left: -10vh;
}
.descriptor-body {
  display: block;
  text-align: justify;
  padding: 0.4em;
  padding-inline: 1em;
  font-size: var(--secondary-font-size);
}
.descriptor-body >>> p {
  margin-bottom: 0 !important;
}
.btn-center {
  display: flex;
  justify-content: center !important;
  margin-inline: auto !important;
}
.outlined-lr-div-secondary {
  border-inline: 1px solid var(--secondary-color) !important;
}
.outlined-lr-div-black {
  border-inline: 1px solid black !important;
}
.outlined-tlr-div-black {
  border: 1px solid black !important;
}
.outlined-div-black {
  border: 1px solid black !important;
}
.outlined-div-secondary {
  border: 1px solid var(--secondary-color);
}
.border-bottom-black {
  border-bottom: 1px solid black !important;
}
.border-bottom-secondary {
  border-bottom: 1px solid var(--secondary-color) !important;
}
.selection-info {
  transform: translate(10%, -60%);
  width: 12px;
  height: 12px;
}
@media (max-width: 991px) {
  .selection-info {
    transform: translate(0%, -100%);
  }
}
@media (max-width: 450px) {
  .descriptor-title-check {
    margin-left: 1em;
  }
}
</style>